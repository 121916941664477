







import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Checkbox from '@/components/Controls/Checkbox.vue'
import { DropdownOption } from '@/components/Controls/Dropdown.vue'
import { Services } from 'truemarket-modules/src/services'
import EnumValueType from 'truemarket-modules/src/models/api/enumerations/EnumValueType'

@Component({
  components: {
    ValidationProvider,
    Checkbox
  }
})
export default class AccommodationFeatures extends Vue {
  onInput () {
    this.$emit('input', this.accommodationFeatures.filter((f) => f.selected).map((f) => f.value))
  }

  @Prop({ required: true })
  public value!: string[]

  @Prop({ required: true })
  public featureType!: 'Commercial' | 'Industrial' | 'Retail'

  @Watch('value')
  onValueChanged (newVal: string[]) {
    this.accommodationFeatures.forEach((f) => {
      f.selected = newVal.includes(f.value)
    })
  }

  mounted () {
    let enumValueType: EnumValueType | null = null

    switch (this.featureType) {
      case 'Commercial':
        enumValueType = EnumValueType.CommercialAccommodationFeatures
        break
      case 'Industrial':
        enumValueType = EnumValueType.IndustrialAccommodationFeatures
        break
      case 'Retail':
        enumValueType = EnumValueType.RetailAccommodationFeatures
        break
    }

    Services.API.Enumerations.GetEnumValuesForType(enumValueType).then((values) => {
      this.accommodationFeatures = values.map(
        (v) => {
          return {
            label: v.Label,
            value: v.ValueId,
            fieldId: v.Value,
            selected: this.value.includes(v.ValueId)
          }
        }
      )
    })
  }

  accommodationFeatures: DropdownOption[] = []
}
